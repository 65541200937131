export default {
    FRONT_URL: 'https://vm-bisite-59.der.usal.es/',
    BACK_IP: 'https://smartcrutches.der.usal.es:2777/api',
    PORT: '8080',
    BACK_IP_USER: '/user',
    BACK_IP_PATOLOGIAS: '/pathology',
    BACK_IP_RETOS: '/challenges',
    BACK_IP_TIPO_RETOS: '/challenge_type',
    BACK_IP_ALERTS: '/alerts',
    BACK_IP_TIPOS_ALERTAS: '/alert_type',
    BACK_IP_CONSEJOS: '/advice/all',
    BACK_IP_CONSEJO: '/advice',
    BACK_IP_SESSIONS: '/session',
}

